import LocaleService from 'infrastructure/i18n/LocaleService';
import { IUserDashboardsDTO } from '../infrastructure/dto/UserDashboardsDTO';
import { UserTranslations } from '../infrastructure/i18n/typing';
import { UserText } from '../infrastructure/i18n/UserText';
import { Dashboards } from 'modules/shared/domain/UserRole';

const userTranslations: UserTranslations = {
  ...UserText[LocaleService.getLanguage()]
};

export interface IUserDashboards {
  id: number;
  boardName: string;
}
interface GenerateFromDTOParams {
  dashboards: IUserDashboardsDTO[];
}
class UserDashboardsData {
  dashboards: IUserDashboards[];

  constructor({ dashboards }: UserDashboardsData) {
    this.dashboards = dashboards;
  }

  static generateFromDTO({
    dashboards
  }: GenerateFromDTOParams): UserDashboardsData {
    let formatDashboards: IUserDashboards[] = [];
    if (dashboards.length) {
      dashboards.forEach((dashboardItem) => {
        formatDashboards.push({
          id: dashboardItem.id,
          boardName:
            userTranslations[Dashboards[dashboardItem.id]] ??
            dashboardItem.board_name
        });
      });
    }
    return new UserDashboardsData({
      dashboards: formatDashboards
    });
  }
}

export default UserDashboardsData;
