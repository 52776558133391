import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import {
  MasterDataPanelDTO,
  UpdaterDTO
} from '../infrastructure/dto/mastersDataDTOMapper';
import { MastersPanelKeys } from './MastersPanel';
import { MastersTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { MastersText } from 'modules/inbound/shared/infraestructure/i18n/mastersText';
import { UserTranslations } from 'modules/inbound/users/infrastructure/i18n/typing';
import { UserText } from 'modules/inbound/users/infrastructure/i18n/UserText';
import { Dashboards } from 'modules/shared/domain/UserRole';
import { EntriesTranslations } from 'modules/inbound/entries/infrastructure/i18n/typing';
import { EntriesText } from 'modules/inbound/entries/infrastructure/i18n/EntriesText';

const NUM_ENTRIES_PANEL = 10;
const mastersPanelTranslations: MastersTranslations &
  UserTranslations &
  EntriesTranslations = {
  ...MastersText[LocaleService.getLanguage()],
  ...UserText[LocaleService.getLanguage()],
  ...EntriesText[LocaleService.getLanguage()]
};

export interface Responsible {
  name: string;
  email: string;
}

const patternResponsibleName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü ]{1,50}$/;
export const validateResponsibleName = (responsibleName: string): boolean => {
  return patternResponsibleName.test(responsibleName);
};

class MasterDataPanel {
  masterName: MastersPanelKeys;
  lastUpdate: string;
  responsible: Responsible;
  updater: string;
  affectedPanels: string[];

  constructor({
    masterName,
    lastUpdate,
    responsible,
    updater,
    affectedPanels
  }: MasterDataPanel) {
    this.masterName = masterName;
    this.lastUpdate = lastUpdate;
    this.responsible = responsible;
    this.updater = updater;
    this.affectedPanels = affectedPanels;
  }

  private static _formatUpdater(updater: UpdaterDTO): string {
    let formattedUpdater: string = `${mastersPanelTranslations.updatedBy}${updater.name}`;
    if (updater.email) {
      formattedUpdater += ` (${updater.email})`;
    }
    return formattedUpdater;
  }

  static generateFromDTO({
    master_name,
    last_update,
    responsible,
    updater,
    affected_panels
  }: MasterDataPanelDTO): MasterDataPanel {
    let formattedAffectedPanels: string[] = [];
    affected_panels.forEach((panel) => {
      if (panel === NUM_ENTRIES_PANEL) {
        formattedAffectedPanels.push(mastersPanelTranslations.entriesNextHours);
        formattedAffectedPanels.push(mastersPanelTranslations.entriesInPlant);
      } else {
        formattedAffectedPanels.push(
          mastersPanelTranslations[Dashboards[panel]]
        );
      }
    });
    return new MasterDataPanel({
      masterName: master_name as MastersPanelKeys,
      lastUpdate: last_update,
      responsible: {
        name: responsible?.name ?? NO_DATA,
        email: responsible?.email ?? NO_DATA
      },
      updater: updater?.name ? this._formatUpdater(updater) : '',
      affectedPanels: formattedAffectedPanels
    });
  }
}

export default MasterDataPanel;
